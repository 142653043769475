import { Ref } from "vue";
import { minMaxValueMessage } from "@/composables/validationMessages";
import { FormItemRule } from "naive-ui";

const costingAdjustmentFieldRules = (isDirty: Ref<boolean>): FormItemRule => {
  return {
    validator(_rule: FormItemRule, value: string): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        if (!isDirty.value) {
          resolve();
          return;
        }
        if (+value < -100 || +value > 100) {
          reject(Error(minMaxValueMessage("Adjustment", -100, 100)));
        } else {
          resolve();
        }
      });
    },
    trigger: ["input", "blur"],
  };
};

export default costingAdjustmentFieldRules;
