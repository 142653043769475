import { Ref } from "vue";
import {
  maxLengthMessage,
  requiredMessage,
} from "@/composables/validationMessages";
import { FormItemRule } from "naive-ui";

const costingExplanationFieldRules = (
  isDirty: Ref<boolean>,
  adjustmentForm: Ref<{ adjustment: number }>,
  isChangeAdjustment: Ref<boolean>,
  isChangeExplanation: Ref<boolean>
): FormItemRule => {
  return {
    validator(_rule: FormItemRule, value: string): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        if (!isDirty.value || !adjustmentForm.value.adjustment) {
          resolve();
          return;
        }
        const isExplanationRequired =
          (isChangeAdjustment.value || isChangeExplanation.value) &&
          adjustmentForm.value.adjustment !== 0;
        if (isExplanationRequired && !value) {
          reject(Error(requiredMessage("Explanation")));
        } else if (isExplanationRequired && value.length > 400) {
          reject(Error(maxLengthMessage("Explanation", 400)));
        } else {
          resolve();
        }
      });
    },
    trigger: ["input", "blur"],
  };
};

export default costingExplanationFieldRules;
